@import '../import';

#panesContainer > .col-sm > .Pane {
	height: 400px;
}
#pane3_1 {
	height: 260px;
}
#pane3 {
	> .toolbar {
		padding-right: 15px;
		.Checkbox {
			align-self: center;
		}
	}
}