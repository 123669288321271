@import '../_import';

.Collapsible {
	position: relative;

	header {
		display: flex;
		background-color: $divider-colour;
		border-bottom: 1px solid #FFF;
		padding: 10px 16px 10px 32px;
		cursor: pointer;

		@at-root {
			.async#{&} {
				background-color: transparent;
			}
		}

		&:hover:before {
			color: $primary-colour;
		}
		h1 {
			font-size: inherit;
			font-weight: inherit;
			line-height: inherit;
			text-transform: uppercase;
			margin: 0;
		}
		&:before {
			content: "keyboard_arrow_right";
			width: 24px;
			top: 7px;
			left: 5px;
			@include material-icon-style;
		}
	}

	&.open > header:before {
		content: "keyboard_arrow_down";
	}
}

// this probably should be somewhere else...
// the parent button should have 'position: relative' set
.icon-stack {
	margin-top: -3px;
	margin-bottom: 3px;
	&:first-child {
		position: absolute;
		top: 18px;
	}
}