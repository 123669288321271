@import '../import';

.payout-filter {
	.dropdown-item:nth-child(3),
	.dropdown-item:nth-child(5),
	.dropdown-item:nth-child(7)
	 {
		border-top: 1px solid $divider-colour;
	}
}

#payoutsReport {
	.Table-col:last-child {
		margin-right: 30px;
	}
	.Table-row {
		text-align: center;
		.btn-icon {
			@include item-button;
			margin-right: 4px;
		}
	}
	.footer-form {
		display: flex;
		align-items: center;
		margin-right: auto;
	}
	.InputWithButton .form-control[readonly] {
		background-color: #FFF;
	}
}

.Pane-paginated .page-info {
	height: 100%;
	line-height: 48px;
	width: 50px;
	text-align: center;
	border-left: 1px solid $border-colour;
	background-color: #FFF;
	cursor: default;
}