@import './import';

// put stuff here that needs to override everything else as it is loaded last

body {
	font-family: Montserrat, sans-serif;
	font-size: 13px;
	background-color: #FFF;
	color: $text-colour;
}
a {
	color: $primary-colour;
	text-decoration: none;
	&:hover, &:focus {
		color: $primary-colour;
	}
}
label {
	margin: 0;
	&[disabled] {
		opacity: 0.65;
	}
}

::-webkit-scrollbar {
	height: 10px;
	width: 10px;
}
::-webkit-scrollbar-track, ::-webkit-scrollbar-corner {
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: #CCC;
	border-radius: 4px;
}

input[type="search"]::-webkit-search-cancel-button {
	display: none;
}

.scrolling {
	overflow-x: hidden;
	overflow-y: auto;
}
.clipped {
	overflow: hidden;
}

.dropdown-menu {
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	font-size: inherit;
}

.btn {
	color: #FFF;
	font-size: 14px;
	line-height: 1.45;
	border: none;
	transition: $transition;
	&:hover {
		color: #FFF;
	}
}
.btn.focus,
.btn:focus,
.btn:hover,
.btn:active,
.btn.active {
		box-shadow: unset !important;
		outline: 0;
}
.btn:disabled {
	opacity: .5;
}
.btn .material-icons {
	@include material-icon(24px);
	vertical-align: middle;
}
.btn-icon {
	padding: 5px;	// 5px 12px
}
.btn-primary,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:disabled {
	background-color: $primary-colour;
	text-transform: uppercase;
	&:not([disabled]):focus,
	&:not([disabled]):hover,
	&:not([disabled]):active,
	&:not([disabled]).active {
		color: #fff;
		background-color: darken($primary-colour, 10%);
		}
}
.btn-secondary,
.btn-secondary.focus,
.btn-secondary:active,
.btn-secondary:disabled {
	background-color: $secondary-colour;
	text-transform: uppercase;
	&:not([disabled]):focus,
	&:not([disabled]):hover,
	&:not([disabled]):active,
	&:not([disabled]).active {
		color: #fff;
		background-color: #999;
	}
}

.btn-dropper {
	padding: 0;
	color: inherit;
	.material-icons {
		display: block;
		width: 30px;
		overflow: hidden;
		opacity: .5;
		font-size: 30px;
		transition: $transition;
	}
	&:hover {
		color: inherit;
	}
}

.form-row {
	margin-bottom: 10px;
	@include media-breakpoint-down(xs) {
		[class*="col-sm"] + [class*="col-sm"] {
			margin-top: 10px;
		}
	}
	@include media-breakpoint-down(sm) {
		[class*="col-md"] + [class*="col-md"] {
			margin-top: 10px;
		}
	}
	.w-100 + .w-100 {
		margin-top: 10px;
	}
}

.form-fieldset {
	border: 1px solid $border-colour;
	border-radius: $control-border-radius;
	padding: 1rem;
	& + & {
		margin-top: 1rem;
	}
}

.form-message {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
}

.form-stacked {
	display: grid;
	grid-template-columns: 100%;
	row-gap: 10px;
}

.form-button {
	line-height: 1.5;
}

.form-control {
	color: inherit;
	font-size: inherit;
	border-color: $border-colour;
	&:focus {
		border-color: $primary-colour;
		@include focus-shadow;
	}
	&:disabled {
		cursor: default;
		background-color: #FFF;
		opacity: $disabled-opacity;
	}
	&[readonly] {
		background-color: #FAFAFA;
	}

	&_borderless {
		border-width: 0;
	}

	&[type="password"] {
		// gives a consistent look to password fields rather than pointlessly matching whatever font is chosen
		font-family: monospace;
	}

	// makes a row take up the space of 2 rows
	// as a single row height is calculated, we should probably make these calculated too
	.form-row &_2h {
		height: 76px;
	}

	// makes a row take up the space of 3 rows
	.form-row &_3h {
		height: 121px;
	}

	// you guessed it...
	.form-row &_4h {
		height: 164px;
	}

	::-webkit-input-placeholder { // Chrome/Opera/Safari
		color: $placeholder-colour;
		font-family: Montserrat, sans-serif;	// this is useful for password fields that use a different font
		opacity: 1;
	}
	&:-moz-placeholder { // Firefox 18-
		color: $placeholder-colour;
		font-family: Montserrat, sans-serif;
		opacity: 1;
	}
	&::-moz-placeholder { // Firefox 19+
		color: $placeholder-colour;
		font-family: Montserrat, sans-serif;
		opacity: 1;
	}
	&:-ms-input-placeholder { // IE 10+
		color: $placeholder-colour;
		font-family: Montserrat, sans-serif;
		opacity: 1;
	}
	&::-ms-input-placeholder { // Microsoft Edge
		color: $placeholder-colour;
		font-family: Montserrat, sans-serif;
		opacity: 1;
	}
	&::placeholder {
		color: $placeholder-colour;
		font-family: Montserrat, sans-serif;
		opacity: 1;
	}
	&.w50, &.w80, &.w100 {
		display: inline;
	}
}

.form-control_buttoned {
	display: flex;

	// the "!important"s mean that regardless of other css, where the control and button join is always square
	.form-control {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	.btn {
		margin-left: -1px;
		border: 1px solid $border-colour;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		padding: 0 4px;
		color: #999;
		background-color: $toolbar-colour;
		&:not(:disabled):hover {
			color: #666;
		}
		&:not(:disabled):focus {
			color: #666;
			border-color: $primary-colour;
			@include focus-shadow;
			transform: rotate(0);
		}
	}

	.form-control, .btn {
		&:focus {
			transform: rotate(0);
		}
	}
}

.controls-stacked {

	> .form-control {
		border-radius: 0;
		&:focus, &.validation-fail {
			// this helps show the border even when it is overlapped with another (i.e. affects stacking context) - it.s not perfect...
			transform: rotate(0);
		}
		&:first-child {
			border-top-left-radius: $control-border-radius;
			border-top-right-radius: $control-border-radius;
		}
		&:not(:first-child) {
			margin-top: -1px;
		}
		&:last-child {
			border-bottom-left-radius: $control-border-radius;
			border-bottom-right-radius: $control-border-radius;
		}
	}

	&_row {
		display: flex;
		> .form-control {
			&:focus, &.validation-fail {
				// this helps show the border even when it is overlapped with another (i.e. affects stacking context) - it.s not perfect...
				transform: rotate(0);
			}
			&:first-child {
				border-top-right-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
			}
			&:not(:first-child) {
				margin-left: -1px;
				border-top-left-radius: 0 !important;
				border-bottom-left-radius: 0 !important;
			}
		}
		.Checkbox + .Checkbox, .Radio + .Radio {
			margin-top: 0;
		}
	}

	> .dropdown, .form-control_buttoned, &_row {
		> .form-control, .btn {
			border-radius: 0;
		}
		&:first-child {
			> .form-control, .btn {
				border-top-left-radius: $control-border-radius;
				border-top-right-radius: $control-border-radius;
			}
		}
		&:not(:first-child) {
			margin-top: -1px;
		}
		&:last-child {
			> .form-control, .btn {
				border-bottom-left-radius: $control-border-radius;
				border-bottom-right-radius: $control-border-radius;
			}
		}
	}

	.Checkbox, .Radio {
		&-label {
			width: 100%;
		}
	}
}

// in between no-gutters and BS default (15px)
.gutters-5 {
	margin-right: -5px;
	margin-left: -5px;
	> .col, > [class*="col-"] {
		padding-right: 5px;
		padding-left: 5px;
	}
}
.gutters-10 {
	margin-right: -10px;
	margin-left: -10px;
	> .col, > [class*="col-"] {
		padding-right: 10px;
		padding-left: 10px;
	}
}
.gutters-mbl-10 {
	@include media-breakpoint-down(md) {
		margin-right: -10px;
		margin-left: -10px;
		> .col, > [class*="col-"] {
			padding-right: 10px;
			padding-left: 10px;
		}
	}
}
.gutters-30 {
	margin-right: -30px;
	margin-left: -30px;
	> .col, > [class*="col-"] {
		padding-right: 30px;
		padding-left: 30px;
	}
}
.gutters-lgu-30 {
	@include media-breakpoint-up(lg) {
		margin-right: -30px;
		margin-left: -30px;
		> .col, > [class*="col-"] {
			padding-right: 30px;
			padding-left: 30px;
		}
	}
}
.gutters-xlu-15 {
	@include media-breakpoint-up(xl) {
		margin-right: -15px;
		margin-left: -15px;
		> .col, > [class*="col-"] {
			padding-right: 15px;
			padding-left: 15px;
		}
	}
}

.gap5 {
	grid-gap: 5px;
}
.gap10 {
	grid-gap: 10px;
}
.gap15 {
	grid-gap: 15px;
}
.gap20 {
	grid-gap: 20px;
}

.w50 {
	width: 50px;
}
.w80 {
	width: 80px;
}
.w100 {
	width: 100px;
}

.input-group {

	&-prepend &-text {
		border-right-color: transparent;	// this works better when using opacity for disabled etc
	}
	&-append &-text {
		border-left-color: transparent;	// this works better when using opacity for disabled etc
	}
	&-prepend .btn,
	&-append .btn {
		color: #999;
		border: 1px solid #ccc;
		background-color: #F5F5F5;
		padding: 0.225rem;

		&:hover:not([disabled]) {
			color: #666;
		}
		&:focus {
			color: #666;
			border-color: $primary-colour;
			@include focus-shadow;
		}
	}

	&_disabled {
		.input-group-prepend,
		.input-group-append {
			opacity: 0.65
		}
	}

	&-text {
		font-size: inherit;
		background-color: $pane-background-colour;
	}

	&.primary .btn {
		border-color: $primary-colour;
		background-color: $primary-colour;
		color: #FFF;
		&:hover:not([disabled]) {
			background-color: darken($primary-colour, 10%);
			color: #FFF;
		}
	}
}

.nav-tabs {
	border-color: $border-colour;
	color: $text-colour;
	.nav-item {
		.nav-link {
			border-radius: $control-border-radius $control-border-radius 0 0;
			transition: $transition;
			cursor: pointer;
			text-transform: uppercase;
			white-space: nowrap;
			&:hover {
				border-color: $border-colour;
			}
			&.active {
				background-color: $toolbar-colour;
				border-color: $border-colour;
			}
		}
	}
}

.validation-fail {
	border-color: $primary-colour!important;
	> .form-control-btn {
		// this makes a DropdownButton's button turn red on failed validation
		border-color: $primary-colour!important;
	}
	> label > span:before {
		// this makes radio button outer ring turn red on failed validation
		border-color: $primary-colour!important;
	}
}

/* background animations for async events (i.e. ajax api calls) */
.async {
	// background-image: linear-gradient(-45deg, rgba(#FFF, 0.2) 25%, transparent 25%, transparent 50%, rgba(#FFF, 0.2) 50%, rgba(#FFF, 0.2) 75%, transparent 75%, transparent);
	background-image: linear-gradient(-45deg, rgba(#000, 0.1) 25%, transparent 25%, transparent 50%, rgba(#000, 0.1) 50%, rgba(#000, 0.1) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
	animation: async 0.35s linear infinite;
}

@keyframes async {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}

:-moz-ui-invalid {	// stop FF adding red glow to required fields
	box-shadow: none;
}

.ws_pre {
	white-space: pre;
}

// some utility margin and padding classes
$sides: 'left', 'top', 'right', 'bottom';

@each $side in $sides {
	$fc: str-slice($side, 1, 1);
	@for $i from 0 to 5 {
		.p#{ $fc }#{ $i * 5 } {
			padding-#{ $side }: #{ $i * 5 }px !important;
		}
		.m#{ $fc }#{ $i * 5 } {
			margin-#{ $side }: #{ $i * 5 }px !important;
		}
	}
}
@for $i from 0 to 5 {
	.pv#{ $i * 5 } {
		padding-top: #{ $i * 5 }px !important;
		padding-bottom: #{ $i * 5 }px !important;
	}
	.mv#{ $i * 5 } {
		margin-top: #{ $i * 5 }px !important;
		margin-bottom: #{ $i * 5 }px !important;
	}
	.ph#{ $i * 5 } {
		padding-left: #{ $i * 5 }px !important;
		padding-right: #{ $i * 5 }px !important;
	}
	.mh#{ $i * 5 } {
		margin-left: #{ $i * 5 }px !important;
		margin-right: #{ $i * 5 }px !important;
	}
}

.mh100 {
	max-height: 50rem;
}