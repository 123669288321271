@import 'import';

#table1 {
	margin-bottom: 1rem;
	.Table-col {
		padding: 0.25rem;
		font-weight: 700;
		text-align: center;
	}
	.Table-cell {
		padding: 0.25rem;
		&:not(:first-child) {
			text-align: center;
		}
	}
}

#table2 {
	.Table-col,
	.Table-cell {
		.material-icons {
			color: #666;
			cursor: pointer;
			transition: 0.2s ease-in-out;
		}
	}
}

#table3 {
	.Pane-header .Table-col {
		text-align: left;
	}
}