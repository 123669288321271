@import 'import';

.Table {
	
	&-row {
		display: flex;
		align-items: center;
	}

	&-col,
	&-cell {
		flex-grow: 1;
		flex-basis: 0;
		max-width: 100%;
	}
	
	&_bordered {
		border: 1px solid $border-colour;
		.Table-row:not(:first-of-type) {
			border-top: 1px solid $border-colour;
		}
		.Table-col,
		.Table-cell {
			&:not(:first-of-type) {
				border-left: 1px solid $border-colour;
			}
		}
	}

	&_striped {
		.Table-row:nth-of-type(2n+1) {
			background-color: $stripe-colour;
		}
	}

	&_selectable &-row {
		@include row;
		border-left: 4px solid transparent;
		padding: 0;
	}
}