@import '../_import';

@mixin feature-link {
	color: $text-colour;
	&:hover,
	&:focus,
	&.active {
		outline: 0;
		text-decoration: none;
		color: $primary-colour;
		border-color: $primary-colour;
		transition: $transition;
	}
}

.FeatureNav {
	position: relative;
	flex: 0 1 100%;
	border-top: 1px solid $light-border-colour;
	height: 40px;
	@include media-breakpoint-up(sm) {
		display: flex;
		align-items: center;
		height: 50px;
	}
	@include media-breakpoint-up(md) {
		border-width: 0;
	}

	&-link {
		height: 100%;
		padding-left: 1rem;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		border-width: 0 0 0 4px;
		border-style: solid;
		border-color: transparent;
		transition: border-color .15s linear;
		white-space: nowrap;
		background-color: #FFF;
		@include feature-link;
		@include media-breakpoint-up(sm) {
			padding: 0 1vw;
			margin-left: 2vw;
			border-width: 0 0 3px 0;
		}
		> span {
			& + span {
				margin-left: 10px;
			}
			&.material-icons {
				margin: -5px 0;
			}
		}
	}
	
	&_end {
		position: absolute;	// this works best for all screen sizes (rather then having it as a flex item)
		top: 13px;
		right: 15px;
		.btn {
			padding: 0;
			@include feature-link;
		}
	}
}