#resizeDialog {
	.Modal-body {
		padding: 20px;
		.Cropper-container {
			position: relative;
			height: 400px;
		}
	}
	.Slider {
		margin-top: 3px;
		width: 300px;
	}
}