@import '../import';

$dot-size: 12px;

.PhotoCarousel {
	.btn {
		position: absolute;
		margin: auto 0;
		height: 24px;
		width: 24px;
		top: 0;
		bottom: 0;
		outline: 0 !important;
		& .material-icons {
			position: absolute;
			left: 0;
			top: 0;
			color: $text-colour;
			opacity: .8;
			transition: $transition;
			&:hover {
				opacity: 1;
			}
		}
	}
	&-photo {
		height: 100%;
		width: 100%;
		background-size: cover;
		color: $primary-colour;
		text-shadow: 1px 1px $text-colour;
		text-align: right;
		cursor: pointer;
	}
	.carousel__slider:focus {
		outline: 0;
		border: 1px solid $primary-colour;
		@include focus-shadow;
	}
	.carousel__slide-focus-ring {
		outline: 0;
	}
	.carousel__dot-group {
		text-align: center;
		line-height: 1;
		button {
			border: none;
			padding: 0;
			margin: 0 2px;
			border-radius: calc($dot-size / 2);
			width: $dot-size;
			height: $dot-size;
			line-height: $dot-size;
			background-color: $border-colour;
			&.carousel__dot--selected {
				background-color: $primary-colour;
			}
		}
	}
}

.PhotoViewer {
	width: 100%;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	&-mask {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 10px;
		background-color: rgba(0, 0, 0, 0.75);
		visibility: hidden;
		opacity: 0;
		// transition: $transition-slow;
		transition: $transition;
		z-index: 1000;
		&_show {
			visibility: visible;
			opacity: 1;
		}
		.btn-close {
			color: #EEE;
			background-color: $primary-colour;
			position: absolute;
			top: 0;
			right: 0;
			height: 40px;
			width: 40px;
			padding: 8px;
			border-radius: 0;
		
			&:hover,
			&:focus {
				color: #FFF;
			}
		}
	}
}