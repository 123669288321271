@import '../import';

#customersReport {
	.Table-row {
		> div {
			text-align: center;
			&:nth-child(2) {
				text-align: left;
			}
		}
		.material-icons {
			color: $control-colour;
			cursor: pointer;
			transition: $transition;
			&:hover {
				color: $primary-colour;
			}
		}
	}
	.mode-info {
		align-self: center;
		margin: 0 auto 0 1rem;
	}
}

.LoyaltyRewards {
	p {
		margin: 0;
	}
	.Pane .Pane-header {
		display: flex;
		align-items: center;
	}
}