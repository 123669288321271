@import 'import';

.CustomerRating {
	display: flex;
	text-align: center;
	line-height: 1;
	padding-right: 10px;
	&-rating {
		font-size: 32px;
		margin-right: 5px;
	}
	&-stars {
		white-space: nowrap;
	}
	&-ratings {
		white-space: nowrap;
	}
	.material-icons {
		font-size: 14px;
		color: $primary-colour;
	}
}