@import 'import';

.MenuItems {
	.Pane-item {
		display: flex;
		align-items: center;
		gap: .5rem;
		padding: 0 12px 0 8px;
		> div:first-child {
			margin-right: auto;
			// text-align: center;
			// min-width: 1.5rem;
		}
		// > div:nth-child(2) {
		// 	margin-right: auto;
		// }
		> div:last-child {
			text-align: right;
			min-width: 2.5rem;
		}
		&-tag {
			white-space: nowrap;
			font-size: 11px;
			border-radius: 4px;
			color: #FFF;
			background-color: $secondary-colour;
			padding: 4px 6px;
			&_primary {
				background-color: $primary-colour;
			}
		}
	}
}