@import 'import';

.UploaderPane {
	height: 268px;
	background-color: $pane-background-colour;
	.Pane-body {
		background-image: url(/assets/img/dish.svg);
		background-position: center center;
		background-repeat: no-repeat;
	}
	
	&-photo {
		cursor: pointer;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		transition: $transition;
	}

	&_dragover {
		// background-color: #FFF;
		border-color: $primary-colour;
		@include focus-shadow;
	}
	button {
		text-transform: uppercase;
	}
}