@import '../_import';

#errorDialog {
	.Modal-body {
		max-height: 450px;
	}
	.Modal-footer {
		padding-top: 15px;
		height: auto;
	}
}