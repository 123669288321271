@import '../_import';

.Content {
	&-nav {
		background-color: #FFF;
		border-top: 1px solid $light-border-colour;
		display: inline-block;
		width: 100%;
		@include media-breakpoint-up(md) {
			display: flex;
		}
	
		.VenueStatusControl-container {
			flex: 0 0 auto;
			order: 100;
			height: 50px;
			margin-left: auto;
			border-style: solid;
			border-color: $light-border-colour;
			border-width: 0;
			@include media-breakpoint-up(md) {
				border-left-width: 1px;
			}
			button {
				display: flex;
				text-align: right;
				height: 50px;
				padding: 0 30px 0 15px;
			}
		}
	}
}