@import 'import';

#menusPane {
	min-height: 200px;
	.Collapsible header button {
		@include item-button;
	}
	.Pane-menu .Pane-items {
		max-height: 150px;	// stops the pane menu getting clipped on mobile
	}
	@include media-breakpoint-up(md) {
		height: $ipad-pane-height;
		.Pane-menu .Pane-items {
			max-height: ($ipad-pane-height - 2);	// stops the pane menu getting clipped
		}
	}
	.Pane-body {
		background-color: $pane-background-colour;
	}
}

#menuItemPane {
	@include media-breakpoint-up(md) {
		height: $ipad-pane-height;
	}
	.Uploader {
		min-height: 200px;
	}
	// .Pane-body > .form-row {
	// 	> div:first-child {
	// 		margin-bottom: 10px;
	// 		@media (min-width: 576px) {
	// 			margin-bottom: 0;
	// 		}
	// 	}
	// }
	#customerSatisfaction {
		align-items: center;
		column-gap: 0.5rem;
		label {
			padding-left: 10px;
			margin-right: auto;
			&[disabled] {
				opacity: $disabled-opacity;
			}
		}
	}
}