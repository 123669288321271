@import '../_import';

.Flyout {

	.nav {
		margin-top: 20px;
	}

	&_item {
		text-transform: uppercase;
		font-size: 14px;
		color: #FFF;
		padding: 10px;
		white-space: nowrap;
		margin: 5px 0 0 3px;

		background-color: transparent;
		border: none;
		text-align: left;

		&:last-child {
			position: absolute;
			bottom: 0;
			width: 100%;
		}

		&:hover,
		&:focus,
		&.active,
		&.active:hover {
			background-color: #333;
			color: $primary-colour;
			text-decoration: none;
			border-left: 3px solid $primary-colour;
			margin-left: 0;
			outline: 0;
		}

		> .material-icons {
			display: inline-block;
			margin: 0 20px 0 7px;
			width: 24px;
			overflow: hidden;
		}

		> span {
			vertical-align: middle;
		}
	}
}