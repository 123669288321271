@import './import';

.Venues {

	&-footer {
		margin-left: -15px;
		margin-right: -15px;
		bottom: 0;
		left: 200px;
		right: 0;
		transition:	$transition-slow;
		@include media-breakpoint-up(md) {
			margin: 0;
			position: fixed;
		}
		.collapsed & {
			left: 64px;
		}
		.fullscreen & {
			left: 0;
		}
		&_message {
			color: #FFF;
			// padding: 0 5px;
			text-align: center;
			background-color: $primary-colour;
		}
	}
}

#modifierGroupsPane, #containsModifierItems {
	.Pane-items > li:last-child {
		border-bottom: 1px solid $divider-colour;
	}
}