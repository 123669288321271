@import 'import';

.AddEditDialog {

	header {

		padding: 0;
		h1 {
			padding: 17px 20px;
			font-size: inherit;
			margin: 0;
		}

	}

	.Modal-body {
		small {
			color: #BBB;
		}
		.item-form {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}
	}

}