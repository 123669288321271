@import '../import';

$cal-header-colour: $toolbar-colour;
$cal-background-colour: #FFF;
$cal-action-colour: $text-colour;
$cal-active-colour: $primary-colour;
$cal-today-colour: $border-colour;
$cal-border: 1px solid $border-colour;
$cal-border-radius: $control-border-radius;
$cal-transition: $transition;

.Calendar {
	background-color: $cal-background-colour;
	&-container {
		padding: 0!important;
		min-width: 230px!important;
	}
	&-button {
		// text-transform: uppercase;	// usual issue with Greek!
		border: none;
		padding: 0;
		color: $cal-action-colour;
		opacity: 0.7;
		background-color: transparent;
		line-height: 1;
		cursor: pointer;
		transition: $cal-transition;
		&:hover,
		&:active,
		&:focus {
			outline: 0;
			opacity: 1;
		}
	}
	&-header {
		display: flex;
		align-content: center;
		background-color: $cal-header-colour;
		border-bottom: $cal-border;
	}
	// &_days &-header {
	// 	border-bottom-width: 0;
	// }
	&-header &-button {
		flex-grow: 1;
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		white-space: nowrap;
		div {
			margin-left: 1rem;	// balances out the dropper
			display: inline-block;
		}
		* {
			vertical-align: middle;
		}
	}
	&-header &-button_icon {
		flex: 0 0 auto;
		line-height: 1;
		padding: 7px;
	}
	&-body {
		padding-bottom: 5px;
	}
	&-body &-button {
		padding: 10px 0;
	}
	&-row {
		display: flex;
		align-content: center;
		& > div {
			flex: 1;
			text-align: center;
		}
		> *:first-child {
			margin-left: 5px;
		}
		> *:last-child {
			margin-right: 5px;
		}
	}
	&_days &-row:first-child {
		background-color: $cal-header-colour;
		border-bottom: $cal-border;
		padding: 2px 0;
	}
	&-cell {
		flex: 1;
		border: none;
		color: $cal-action-colour;
		background-color: transparent;
		padding: 10px 0 7px;
		line-height: 1;
		border-bottom: 3px solid transparent;
		cursor: pointer;
		transition: $cal-transition;
		&:not([disabled]):hover,
		&:active,
		&:focus {
			outline: 0;
			color: $cal-active-colour;
			border-bottom-color: $cal-active-colour;
		}
		&:disabled {
			opacity: .5;
		}
	}
	&_faded {
		color: lighten($cal-action-colour, 30%);
	}
	&_today {
		border-bottom-color: $cal-today-colour;
	}
	&_active {
		color: $cal-active-colour;
		border-bottom-color: $cal-active-colour;
	}
	&-footer {
		text-align: center;
		button {
			text-transform: uppercase;
			margin: 0 0.5rem 0.5rem;
		}
	}
}