@import 'import';

#menusSearchPane {
	min-height: 200px;
	@include media-breakpoint-up(md) {
		height: $ipad-pane-height;
	}
	input {
		border: none;
		background-color: transparent;
		outline: none;
		color: #FFF;

		&::placeholder {
			text-transform: uppercase;
			color: #FFF;
			opacity: .7;
		}
	}
	.Pane-body {
		background-color: $toolbar-colour;
	}

	.searchSectionHeading {
		padding: 0 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-transform: uppercase;
		display: flex;
		line-height: 24px;
		background-color: $divider-colour;
		.material-icons {
			opacity: .7;
		}
	}
}