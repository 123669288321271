@import '../import';

.VenueStatusControl {
	@include venue-header-control;
	width: fit-content;
	margin-left: auto;
	&-feedback {
		.notification {
			// white-space: nowrap;	// maybe better to set a min-width? (if some translations end up v long)
		}
	}

	&_loading {
		@include notification;
	}

	> button {
		display: flex;
		text-align: right;
		height: 50px;
		padding: 0 30px 0 15px;
	}
}