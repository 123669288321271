@import '../import';

// Note: colours marked @@ NOTE @@ in this file need to match the fill colours in the three tear*.svg files

$tear-size: 5;
$grouping-colour: $secondary-colour;
$normal-colour: #E8E8E8;
$rush-colour: lighten($primary-colour, 30%);
$priority-colour: lighten($warning-colour, 30%);
$unpaid-colour: $primary-colour;

.orders-container {
	border: 1px solid #ccc;
	border-radius: $pane-border-radius;
	background-color: #FFF;
	height: 800px;
	columns: 3;
	grid-column-gap: 1rem;
	column-gap: 1rem;
	padding: 0 1rem;		// the 0 and the commented out first-child stuff in .order removes the top and bottom gaps for "wrapping" tickets
	overflow: hidden;

	.fullscreen & {
		border-width: 0;
	}

	.order {

		margin-top: 1rem;
		// overflow: hidden;	// why was this here??? stops in working in recent browsers

		&-spacer {
			display: block;
			height: 0.5em;
			background-color: $normal-colour;
		}

		&-header {	// really, a header row as it doesn't contain the header contents, just each individual row
			display: flex;
			gap: 1em;
			border-width: 0 0.5em;
			border-style: solid;
			border-color: $normal-colour;
			background-color: $normal-colour;
			white-space: nowrap;
			> div {
				width: 40%;
				&:first-child {
					width: 25%;
					// text-align: right;	// I think this looks better left-aligned
				}
				&:last-child {
					width: 35%;
					text-align: right;
				}
			}
		}

		&-number {
			font-size: 2em;
			line-height: 1;
		}

		&-row {
			display: flex;
			border-width: 0 0.5em;
			border-style: solid;
			border-top: 1px solid #DDD;
			border-left-color: $normal-colour;
			border-right-color: $normal-colour;

			&_first {
				border-top-width: 0;
			}
	
			> div {
				padding: 0.125em 0.35em;
			}
		
			> div:first-child {
				text-align: right;
				flex: 0 0 2.5em;
				border-right: 1px solid #DDD;
			}
		}

		&-modifier {
			border-width: 0 0.5em;
			border-style: solid;
			border-left-color: $normal-colour;
			border-right-color: $normal-colour;
		> div {
				padding: 0.125em 0.35em;
				border-left: 1px solid #DDD;
				margin-left: calc(2.5em - 1px);
			}
		}

		&_rush {
			.order-spacer, .order-comments, .order-footer {
				background-color: $rush-colour;
			}
			.order-header {
				background-color: $rush-colour;
				border-color: $rush-colour;
			}
			.order-row, .order-modifier {
				border-left-color: $rush-colour;
				border-right-color: $rush-colour;
			}
		}

		&_priority {
			.order-spacer, .order-comments, .order-footer {
				background-color: $priority-colour;
			}
			.order-header {
				background-color: $priority-colour;
				border-color: $priority-colour;
			}
			.order-row {
				border-left-color: $priority-colour;
				border-right-color: $priority-colour;
			}
		}

		.order_lapsed {
			color: $error-colour;
		}

		&-grouped {
			&_first .order {
				padding-bottom: 1.5em;
			}
			&_middle {
				margin-top: #{ -$tear-size }px;
				& .order {
					padding-bottom: 1.5em;
				}
			}
			&_last {
				margin-top: #{ -$tear-size }px;
			}
		}

		&_unpaid {
			font-weight: 700;
			color: #FFF;
			background-color: $unpaid-colour;
			text-align: center;
		}

		&-line {
			color: transparent;
		}

		&-comments {
			padding: 0 calc(.25rem + 1px);
			background-color: $normal-colour;
			> div {
				border: 1px solid darken($comments-colour, 30%);
				border-radius: $control-border-radius;
				background-color: $comments-colour;
				padding: 0 .25rem;
			}
		}

		&-footer {
			display: flex;
			justify-content: center;
			column-gap: .5rem;
			background-color: $normal-colour;
			.btn-icon {
				padding: 4px;
			}
		}

		&-tear {
			height: #{ $tear-size }px;
			&:first-child {
				background-image: url(/assets/img/tear-top.svg);
			}
			&:last-child{
				background-image: url(/assets/img/tear.svg);
			}
			background-repeat: repeat-x;
			background-size: #{ $tear-size * 2 }px #{ $tear-size }px;
			&_priority {
				&:first-child {
					background-image: url(/assets/img/tear-top-priority.svg);
				}
				&:last-child{
					background-image: url(/assets/img/tear-priority.svg);
				}
			}
			&_rush {
				&:first-child {
					background-image: url(/assets/img/tear-top-rush.svg);
				}
				&:last-child{
					background-image: url(/assets/img/tear-rush.svg);
				}
			}
		}

	}
}

.Orders-footer {
	height: 70px !important;
	padding-bottom: 10px;
	.IconButtonGroup-value {
		width: 44px;
		padding: 7px 0;
		text-align: center;
	}
	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	label {
		display: block;
	}
	.fullscreen & {
		height: 50px !important;
		label {
			display: none;
		}
	}
	.page-info {
		background-color: $main-background-colour;
	}
}

.control-priority .IconButtonGroup-value {
	background-color: $priority-colour;
}

.control-rush .IconButtonGroup-value {
	background-color: $rush-colour;
}