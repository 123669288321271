@import '../_import';

$pane-grey: $toolbar-colour;
$pane-background-colour: $stripe-colour;

.Pane {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	background-color: #FFF;
	position: relative;
	border: 1px solid $border-colour;
	border-radius: $pane-border-radius;
	margin-bottom: 10px;
	@include pane-shadow;

	// this is the older pre-flex header toolbar design - prefer the flex-based version
	&-toolbar {
		position: absolute;
		top: 0;
		right: 0;
		> .btn {
			text-transform: uppercase;
			padding: 10px;
			line-height: 1;
			margin-top: 7px;
			margin-right: 7px;
			background-color: $primary-colour;
			border: 1px solid lighten($primary-colour, 20%);

			.Pane_inner & {
				background-color: $secondary-colour;
				border-width: 0;
			}

			&:not([disabled]):hover,
			&.active,
			&:focus {
				color: #FFF;
				background-color: darken($primary-colour, 10%);
			}
		}
		> .btn-icon {
			padding: 5px;

			&:first-child {
				margin-left: 7px;
			}

		}
	}

	&-header {
		background-color: $primary-colour;
		border-radius: $pane-border-radius $pane-border-radius 0 0;
		text-transform: uppercase;
		color: #FFF;

		h1 {
			font-size: inherit;
			font-weight: inherit;
			line-height: 20px;
			padding: 15px 20px;
			margin: 0;
			display: block;
		}

		ul {
			margin: 0 15px 0 auto;
			text-transform: none;
			font-style: italic;
		}

		> .Radio, > .Checkbox {
			padding: 15px;
			label {
				color: $text-colour;
			}
		}

		&.row {
			// if bootstrap grid used for header columns
			> div {
				padding: 15px;
				white-space: nowrap;
			}
		}

		&_headings {
			// basically, reproduce .row .no-gutters
			display: flex;
			flex-wrap: wrap;
			margin-left: 0;
			margin-right: 0;
			// then pad to match PaneItems
			padding: 15px 16px;
			> div {
				padding: 0;
			}
		}
	
	}

	// this is the newer flex-based header with right-aligned toolbar design
	&-toolBar {
		height: 50px;
		display: flex;
		align-items: center;
		gap: 7px;
		h1 {
			padding: 0;
		}
		> :first-child {
			margin-left: 20px;
			flex-grow: 1;
		}
		> :last-child {
			margin-right: 20px;
		}
		& .btn-icon {
			text-transform: uppercase;
			line-height: 1;
			border: 1px solid lighten($primary-colour, 20%);
			flex: 0 0 auto;
			width: auto;
	
			&:first-of-type {
				margin-left: auto;
			}
	
			&:last-child {
				margin-right: 7px;
			}
	
			&:not([disabled]):hover,
			&.active,
			&:focus {
				color: #FFF;
				background-color: darken($primary-colour, 10%);
			}
		}
	}

	&-body {
		flex: 1;
		p {
			margin-bottom: 10px;
		}
		p:last-child {	// I added this late in the day - it may break something...
			margin-bottom: 0;
		}

		.form-row {

			> div > .Checkbox, > div > .Radio {
				padding-top: 7px;
				margin: 0 0 6px;
			}

			&_simple {
				margin-bottom: 0;
				> div {
					margin-bottom: 10px;
				}
			}
		}

		.Pane-message {
			margin: 0.5rem;
			@include notification;
			span[role="img"] {
				margin: 0 0.25rem;
			}
		}

		&.container {
			padding-top: 16px;
			padding-bottom: 16px;
			max-width: inherit;
			.form-row:last-child {
				margin-bottom: 0;
			}
			.Pane-message {
				margin: 0 0 1rem;
			}
		}

		&_section {
			padding: 1rem;
			> h2 {
				font-size: inherit;
				background-color: $toolbar-colour;
				margin: -1rem -1rem 1rem -1rem;
				padding: 0.5rem;
				border-bottom: 1px solid $border-colour;
			}
			.form-row:last-child {
				margin-bottom: 0;
			}
			&:not(:last-child) {
				border-bottom: 1px solid $border-colour;
			}
		}

	}

	&-footer {
		height: 50px;
		min-height: 50px;
		border-top: 1px solid $border-colour;
		border-radius: 0 0 $pane-border-radius $pane-border-radius;
		overflow: hidden;
		background-color: $pane-grey;
		display: flex;
		justify-content: flex-start;

		> h1 {
			font-size: inherit;
			font-weight: inherit;
			line-height: 20px;
			padding: 15px 20px;
		}

		> .btn-icon {
			position: relative;	// need this for buttons w overlapping icons
			border-left: 1px solid $border-colour;
			width: 50px;
		}

	}

	// &_table {
	// 	.Pane-header {
	// 		display: flex;
	// 		white-space: nowrap;
	// 		padding: 15px 10px;
	// 	}
	// 	.Pane-body {
	// 		margin-bottom: $pane-border-radius;
	// 		.row {
	// 			padding: 10px;
	// 			margin: 0;
	// 		}
	// 	}
	// }
}

// child panes are grey...
.Pane .Pane:not(.active),
.Pane_inner {
	margin: 0;
	box-shadow: unset;
	> .Pane-header {
		color: $text-colour;
		background-color: $pane-grey;
		border-bottom: 1px solid $border-colour;
		[class*="col-"] {
			color: $text-colour;
		}
		h1 {
			color: $text-colour;
			padding-bottom: 14px;
		}
	}
}

.Pane_floating {
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 0;
	visibility: hidden;
	opacity: 0;
	transition: $transition-slow;
	transition-property: opacity, visibility;
	z-index: 200;

	.Pane-header {
		cursor: move;
	}

	.Pane-footer {
		background-image: url(/assets/img/drag-handle.svg);
		background-repeat: no-repeat;
		background-position: bottom 4px left 4px;
		background-size: 16px;
	}

	&.Pane_show {
		visibility: visible;
		opacity: 1;
	}
}

// The trick here is to make the menu slide down BEHIND the header.
// So that the menu is not visible poking out the pane's top when slid up,
// the pane needs to clip its contents - may not work in every case?
// For instance, what if we want a pane menu and a typeahead near the bottom?

.Pane-menu {
	position: relative;
	z-index: 0;

	&_control {
		width: 100%;
		height: 50px;
		border: none;
		padding: 0 12px;
		background-color: $primary-colour;
		color: #FFF;
		text-transform: uppercase;
		display: flex;
		align-items: center;

		> .material-icons {
			font-size: 30px;
			opacity: .8;
			transition: $transition;
		}
		&:hover > .material-icons {
			opacity: 1;
		}
	}

	> .btn-dropper {
		position: absolute;
		right: 0;
		top: 0;
		padding: 10px 5px;
		
		&:focus {
			.material-icons {
				opacity: 1;
			}
		}
		.material-icons {
			opacity: .8;
			&:hover {
				opacity: 1;
			}
		}

		&.async {
			border-color: inherit;
		}

		&[disabled] {
			opacity: .5;
		}

		i {
			font-size: 30px;
		}
	}

	> h1 {
		background-color: $primary-colour;
	}

	.Pane-items {
		position: absolute;
		z-index: -1;
		top: 50px;
		left: 0;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		color: $text-colour;
		background-color: #FFF;
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
		font-style: normal;
		text-transform: none;
		@include pane-shadow;
		transform: translateY(-100%);
		transition: $transition-slow;
	}

	&.open .Pane-items {
		transform: translateY(0);
	}
}

.Pane .Pane-shadowMask {
	position: absolute;
	z-index: 0;
	display: none;
	left: 0;
	right: 0;
	top: 50px;
	bottom: 0;
	background-color: #000;
	opacity: .1;
}
.Pane.menu-open .Pane-shadowMask {
	display: block;
}
// adjustments so that the menu and shadow mask are in front of the body and footer
.Pane.clipped {
	z-index: 0;

	.Pane-body,
	.Pane-footer {
		position: relative;
		z-index: -1;
	}
}

.Pane-items {
	list-style: none;
	padding: 0;
	margin: 0;
	// background-color: $pane-background-colour;	this seems like a mistake?
	&_inline {	// maybe not the best name but these are when they are NOT dropdowns but always visible
		margin-top: 5px;
		font-size: 14px;
		max-height: 253px;
		border: 1px solid $border-colour;
		border-radius: $control-border-radius;
	}

	// if you require a bottom border, you need to add one yourself (most use cases it is not required i.e. dropdown menus)
	> li:not(:last-child) {
		border-bottom: 1px solid $divider-colour;
	}

	&_striped {
		> *:nth-of-type(2n+2) {
			background-color: $stripe-colour;
		}
	}

	&_p0 {
		.Pane-item > {
			.col, [class*="col-"] {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	&_p5 {
		.Pane-item > {
			.col, [class*="col-"] {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
	&_p10 {
		.Pane-item > {
			.col, [class*="col-"] {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}
	&_indexed {
		.Pane-item > div:first-of-type {
			width: 2rem;
		}
	}
}

.Pane-item {
	@include row;
	&-btn {
		@include item-button;
		.Pane-menu & {
			color: $primary-colour;
		}
		&_floated {
			position: absolute;
			z-index: 1;
			right: 6px;
			top: 6px;
		}
	}
	&_textButton {
		font-weight: 700;
		padding: 6px;
	}
}

.Pane + .Pane,
.Pane .Pane + .Pane {
	// margin-top: 1rem;
	margin-top: 10px;	// trying to consolidate to ipad-friendly design
}