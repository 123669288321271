@import '../import';

.TagList {
	.tag {
		position: relative;
		cursor: pointer;
		label {
			cursor: inherit;
		}
		.material-icons {
			position: absolute;
			right: 1px;
			top: 5px;
			color: inherit;
			opacity: 0;
			font-size: 18px;
		}
		&:hover,
		&:focus,
		&:active {
			label {
				transition: $transition;
				opacity: .6;
			}
			.material-icons {
				transition: $transition;
				opacity: 1;
			}
		}
	}
	.typeahead-container {
		width: 160px;
	}
	.form-control {
		padding: 4px 8px;
		height: auto;
	}
}