@import 'import';

.PageGeneric {
	h1 {
		text-transform: uppercase;
		font-size: 2rem;
	}
	&-details {
		border-top: 1px solid $border-colour;
		margin-top: 2rem;
		padding-top: 1rem;
		text-align: left;
		h2 {
			font-size: 1.2rem;
		}
	}
}