@import '../import';

#taxCodeDialog {
	.nav-tabs {
		.nav-link {
			color: $text-colour;
		}
	}
	.Checkbox {
		width: 140px;
	}
	.tab-content {
		padding: 20px;
		border-width: 0 1px 1px;
		border-style: solid;
		border-color: $border-colour;
		border-radius: 0 0 $pane-border-radius $pane-border-radius;
		> .row {
			align-items: flex-start;
		}
	}
}

#taxCodes {
	height: 415px;
	label {
		width: 80px;
	}
}