@import 'import';

.ValidationMessage {
	@include notification;
	margin-bottom: 16px;
	outline: 0;
	&:focus {
		@include focus-shadow;
	}
	a {
		color: #FFF;
		text-decoration: underline;
		&:hover, &:focus {
			color: #FFF;
		}
	}
}