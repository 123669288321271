@import '../import';

.MapControl {
	padding: 0 !important;
	#about & {
		height: 178px;
	}
	#venueEditorDialog & {
		height: 164px;
	}
}

#openingHours {
	.Pane-items {
		border: 1px solid $border-colour;
		border-radius: $control-border-radius;
		background-color: #FFF;
		padding: 5px 0;
		margin-bottom: .75rem;
	}
	em {
		font-style: normal;
		font-weight: 700;
		color: $primary-colour;
	}
}

#openingTimes {
	.Pane-header {
		display: flex;
		align-items: center;
		text-transform: none;	// avoinding having to translate day names - so need to be lowercase
		h1 {
			font-size: larger;	// ""
		}
	}
	fieldset {
		padding: 0.75rem;
		border: 1px solid $border-colour;
		border-radius: $pane-border-radius;
		& + fieldset {
			margin-top: .75rem;
		}
	}
	footer {
		// padding: 0 1rem;	// not sure why this is here, but it interferes with TimePicker's footers
		> label {
			margin-right: auto;
		}
	}
}

#logoHolder {
	width: 222px;
	height: 222px;
	margin: 1rem auto 0;
	border: 1px solid $border-colour;
	border-radius: $control-border-radius;
	overflow: hidden;
	background-color: #F5F5F5;
	background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(#FFF, 1) 10px, rgba(#FFF, 1) 20px);

	img {
		width: 100%;
	}
}

#venuePhotosPane, #featuredPhotosPane {
	.PhotoCarousel {
		.btn-back {
			left: -17px;
		}
		.btn-next {
			right: -18px;
		}
		margin-top: 1rem;
		.carousel__inner-slide {
			padding: 0 3px;
		}
		&-photo {
			border-width: 0 0 5px; // 5px 5px;
			border-style: solid;
			border-color: #FFF;
			&_selected {
				// border-color: #FFF #FFF #FFF $primary-colour;
				border-bottom-color: $primary-colour;
			}
		}
	}
}

#waitstaffDays {
	@include media-breakpoint-down(sm) {
		margin-top: 10px;
	}
	.Pane-header {
		.col {
			text-align: center;
			padding: 6px;
		}
	}
	.Pane-body {
		.col {
			padding: 5px;
		}
	}
}