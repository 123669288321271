@import '../import';

#waitstaffReport {
	.Pane-header {
		.Table-col:nth-child(2) {
			min-width: 90px;
		}
	}
	.Table-row {
		text-align: center;
		&.striped {
			background-color: $stripe-colour;
		}
		.Table-cell {
			&:first-child {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: left;
			}
			&:nth-child(2) {
				min-width: 90px;
			}
		}
	}
}

#maintainTags {
	.search-container {
		max-width: 300px;
	}
	.tagList {
		margin: 10px 0 0;
		.tag {
			cursor: pointer;
			&:hover {
				border-color: $primary-colour;
			}
		}
	}
}

#maintainCategories {
	.Pane_category {
		flex: 0 0 200px;
		height: 301px;
		&:hover {
			border-color: $primary-colour;
		}
	}
	.Pane-item {
		border-bottom: 1px solid $divider-colour;
	}
}