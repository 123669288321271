@import 'import';

#tagEditorDialog {

	.Pane_category {
		flex: 0 0 150px;
		height: 251px;
		.Pane-body {
			margin-bottom: $pane-border-radius;
		}
		.Pane-item {
			white-space: nowrap;
			border-bottom: 1px solid $divider-colour;
		}
	}
}