@import './_import';

main {
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	@include media-breakpoint-up(xl) {
		padding-top: 40px;
		padding-left: 30px;
		padding-right: 30px;
	}
}

@for $i from 2 to 6 {
	.max-width-#{ $i * 200 } {
		max-width: #{ $i * 200 }px;
	}
}

.toolbar {
	// used on Pane footers and TimePicker

	background-color: $toolbar-colour;
	display: flex;
	justify-content: flex-start;

	> .btn {
		border-radius: 0;
		background-color: transparent;
		color: #999;

		display: flex;
		align-items: center;
		justify-content: center;

		padding: 0;
		opacity: 1 !important;

		&:disabled {
			color: #999;
			span {
				opacity: 0.5 !important;
			}
		}

		&:first-child {
			margin-right: auto;
			border-left-width: 0;
		}

		&:not([disabled]):hover,
		&:focus,
		&.active {
			color: $text-colour;
			background-color: #DDD;
			box-shadow: none;
		}
	}

	&_rightOnly {
		> .btn-icon:first-child {
			margin-right: 0;
			margin-left: auto;
			border-left-width: 1px;
		}
	}
}

.pill {
	display: inline-block;
	border: 1px solid $border-colour;
	border-radius: $control-border-radius;
	vertical-align: middle;
	padding: 2px 8px;
	color: $text-colour;
	background-color: $toolbar-colour;
	&_primary {
		color: #FFF;
		background-color: $primary-colour;
	}
	& + .material-icons {
		vertical-align: middle;
		margin: 0 8px;
		color: #999;
	}
}

.notification {
	display: flex;
	align-items: center;
	overflow: hidden;
	& + .notification {
		margin-top: 0.5rem;
	}
	.material-icons {
		cursor: default;
		color: #999;
		padding-right: 0.5rem;
	}
	&_error .material-icons {
		color: $error-colour;
	}
	&_warning .material-icons {
		color: $warning-colour;
	}
	&_info .material-icons {
		color: $info-colour;
	}
}

.led_off {
	width: 24px;
	height: 24px;
	.led-colour {
		fill: #888;
	}
}
.led_red {
	width: 24px;
	height: 24px;
	.led-colour {
		fill: #FF3333;
	}
}
.led_green {
	width: 24px;
	height: 24px;
	.led-colour {
		fill: #00FF00;
	}
}
.led_amber {
	width: 24px;
	height: 24px;
	.led-colour {
		fill: #FFBB00;
	}
}

.tagList {	// here as it is used in 3 locations: ADMIN > TAGS, LOCATIONS > ABOUT, MENUS > item
	list-style-type: none;
	padding: 8px;
	margin: 0;
	border-radius: $control-border-radius;
	border: 1px solid $border-colour;
	background-color: $toolbar-colour;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	align-content: flex-start;
	gap: 8px;
	.tag {
		background-color: #FFF;
		border-radius: $control-border-radius;
		border: 1px solid $border-colour;
		padding: 4px 8px;
		&.active {
			background-color: $primary-colour;
			border-color: $primary-colour;
			color: #FFF;
		}
	}
}
.tagCategories {	// here as it is used in 2 locations: MENU > MENUS > edit tags + ADMIN > TAGS
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	padding: 1rem;
	.Pane {
		margin: 0;
		&-header {
			white-space: nowrap;
		}
	}
	.Pane + .Pane {
		margin-top: 0;
		margin-left: 1rem;
	}
}

// if this is a separate scss file adjacent to the component, it ends up as a separate css file in the build
// since Strengthometer is lazy loaded, so putting it here leads to one less server request with little impact elsewhere
.Strengthometer {
	display: flex;
	column-gap: 5px;
	&-pill {
		flex: auto;
		height: 5px;
		border: 1px solid $border-colour;
		border-radius: 2px;
		transition: $transition;
	}
}

.table-row {
	display: flex;
	&_selectable {
		@include row;
		padding: 0;
	}
}

$percentages: 5, 10, 14, 16, 20, 25, 28, 30, 42;

@each $percentage in $percentages {
	.table-col_#{$percentage} {
		padding: 10px 5px;
		flex: 0 0 #{$percentage + '%'};
		max-width: #{$percentage + '%'};
	}
}
.table-col {
	padding: 10px 5px;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

.Pane {
	&-header[role=row] {
		text-align: center;
		display: flex;
		padding: 5px 0;
	}
	.Table-heading {
		background-color: $divider-colour;
		.Table-col {
			border-color: $border-colour;
		}
	}
	.Table-row:not(:last-child) {
		border-bottom: 1px solid $divider-colour;
	}
	.Table-col,
	.Table-cell {
		padding: 10px;
	}
	&.Table_selectable .Table-col:first-child {
		padding-left: 14px;
	}


	&-body {
		.Table-cell {
			.material-icons {
				vertical-align: middle;
			}
		}
	}
}

$sides: 'left', 'top', 'right', 'bottom';

@each $side in $sides {
	.divider-#{$side} {
		border-#{$side}: 1px solid $divider-colour;
	}
}