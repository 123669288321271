@import '../_import';

.DropdownButton {
	> button {
		text-align: left;
		padding: 0 20px 0 0;
		outline: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		span {
			display: inline-block;
			padding: 0.475rem 0.75rem;
			color: #333;
			white-space: nowrap;
			&.placeholder {
				color: #999;
				text-transform: none;
			}
		}
		> .material-icons {
			position: absolute;
			width: 24px;
			overflow: hidden;
			color: $control-colour;
			top: 5px;
			right: 5px;
			opacity: .75;
			transition: $transition;
		}
		&:not([disabled]):hover .material-icons {
			opacity: 1;
		}
	}
	.dropdown-menu {
		width: 100%;
		font-size: inherit;
		border-color: $border-colour;
		padding: 5px 0;
		overflow-x: hidden;
		> .dropdown-item {
			@include row;
		}
		&::-webkit-scrollbar {
			background-color: #F8F8F8;
			border-radius:  0 $control-border-radius $control-border-radius 0;
		}
		&_scroll {
			max-height: 324px;	// = 8 rows: (@39px each) + 5 + 5 (top & borrom margin) + 2? (border-width maybe?)
			overflow-y: auto;
		}
	}
}