@import 'import';

.ItemModifierGroups {
	min-height: 200px;
	@include media-breakpoint-up(sm) {
		height: 314px;
	}

	.Pane-header {
		> .Table-col {
			padding-left: 0;
			padding-right: 0;
		}
		> .Table-col:first-child {
			text-align: left;
		}
	}
	.Pane-body {
		background-color: $pane-background-colour;
	}
	.Table-row {
		border-bottom: 1px solid $divider-colour;
		> .Table-cell:nth-child(2), .Table-cell:nth-child(3), .Table-cell:nth-child(4) {
			text-align: center;
			flex: 0 0 40px;
			max-width: 40px;
		}
	}
}
