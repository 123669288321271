@import '../import';

.Feedback ul {
	padding: 0.5rem;
	margin-bottom: 0;
	li {
		p {
			margin: 0;
		}
	}
}

.OrderTypes {
	#deliveryOptions {
		margin: 1rem 0 0 25px;
	}
}

.Billing {
	#paymentSettings {
		background-color: $toolbar-colour;
		.Radio[disabled] {
			opacity: 1;
		}
		.Radio > label > input[type="radio"] + span:after {
			background-color: $text-colour;
		}
	}
}

.ExtraCharges {
	min-height: 202px;
	@include media-breakpoint-up(md) {
		height: 411px;
	}
	.Feedback {
		margin: 1rem;
	}
}

#extraChargeDialog {
	.form-row:last-of-type {
		margin-top: 20px;
		margin-bottom: 0;
	}
	.Radio + .Radio {
		margin-top: 5px;	// squeezing these vertically from the norm so they look more bound to the Amount input
	}
	p {
		margin: 0;
	}
}

.Printers {
	.Table-col:not(:last-child) {
		text-align: left;
	}
	.Table-cell:last-child {
		text-align: center;
	}
	.status {
		// position: absolute;
		// right: 0;
		// top: 8px;
		&_ok {
			color: #4F4;
		}
		&_errors {
			color: $error-colour;
		}
		&_warnings {
			color: $warning-colour;
		}
		&_offline {
			color: #AAA;
		}
	}
}
#printJobs {
	.Table-col {
		&:not(:last-child) {
			text-align: left;
		}
		&:last-child {
			margin-right: 60px;
		}
	}
	.Table-cell:last-of-type {
		text-align: center;
	}
	.btn-icon {
		@include item-button;
		margin-right: 4px;
	}
}
#printerStatus {
	background-color: $toolbar-colour;
}

.form-adjacent {
	// when you have two inputs adjacent to each other - like a password & confirm password field
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(2, 1fr);
	.input-group-text {
		height: 34px;	// I don't know why I need this if a bootstrap input-group is inside a grid - really need to figure this out
	}
}

.Orders {
	.fullscreen & {
		.Content-nav {
			display: none;
		}
		main {
			padding: 0;
		}
	}
	&-footer {
		height: 50px;
		padding: 0 1rem;
		background-color: $toolbar-colour;
		border-top: 1px solid $border-colour;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 1;

		.btn-icon {
			position: relative;
			@include item-button;
			color: $primary-colour;
			padding: 3px 5px;
			.material-icons {
				height: 30px;
				width: 30px;
				font-size: 30px;
			}
			&.active {
				border-color: $primary-colour;
			}
			.icon-stack:first-child {
				top: 10px;
			}
			&[disabled] {
				border-color: inherit;
				opacity: 1 !important;
			}
		}

		.page-info {
			font-size: 16px;
			line-height: 36px;
			width: 40px;
			text-align: center;
			border-top: 2px solid #CCC;
			border-bottom: 2px solid #CCC;
		}
	}
}

.IconButtonGroup {
	display: flex;
	.btn-icon:first-of-type {
		border-radius: $control-border-radius 0 0 $control-border-radius;
	}
	.btn-icon:last-of-type {
		border-radius: 0 $control-border-radius $control-border-radius 0;
	}
	&-value {
		font-size: 16px;
		padding: 7px 15px 0;
		background-color: #FFF;
		border-top: 2px solid $border-colour;
		border-bottom: 2px solid $border-colour;
	}
}

#bankingForm {
	[class*="led_"] {
		height: 33.5px;
		margin-right: 0.5rem;
	}
}