@import "../import";

.SadFace {
	position: relative;
	background-color: $primary-colour;
	left: 0;
	right: 0;
	width: 200px;
	height: 200px;
	border-radius: 150px;

	&-eye {
		position: absolute;
		top: 40px;
		width: 30px;
		height: 50px;
		background: #fff;
		border-radius: 100px / 160px;

		&_left {
			left: 60px;
			-webkit-transform: rotate(10deg);
			transform: rotate(10deg);
		}

		&_right {
			right: 60px;
			-webkit-transform: rotate(-10deg);
			transform: rotate(-10deg);
		}
	}

	&-frown {
		position: absolute;
		bottom: 50px;
		left: 35px;
		width: 130px;
		height: 40px;
		border: 10px solid #fff;
		border-bottom-color: transparent;
		border-radius: 80px 80px 0 0 / 50px 50px 0 0;

		&:before {
			content: "";
			position: absolute;
			display: block;
			left: -11px;
			top: 17px;
			width: 10px;
			height: 20px;
			background: #fff;
			border-radius: 100px/160px;
			-webkit-transform: rotate(-65deg);
			transform: rotate(-65deg);
		}

		&:after {
			content: "";
			position: absolute;
			display: block;
			left: 111px;
			top: 17px;
			width: 10px;
			height: 20px;
			background: #fff;
			border-radius: 100px/160px;
			-webkit-transform: rotate(65deg);
			transform: rotate(65deg);
		}
	}
}