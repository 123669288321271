@import 'import';

#menuItemPane {

	.simple-form {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.PriceOptions .form-control:first-child {
		background-color: $pane-background-colour;
		.Radio-label {
			white-space: nowrap;
		}
	}

	.item-state {
		.form-control {
			background-color: $pane-background-colour;
		}
		.Checkbox-label {
			white-space: nowrap;
		}
	}

	textarea.form-control {
		height: 148px;
		&.smaller {
			height: 105px;
		}
	}

	.tagList {
		min-height: 84px;
		&_empty {
			align-content: center;
			justify-content: center;
			li {
				text-align: center;
				.material-icons {
					color: #999;
				}
			}
		}
		&_disabled li {
			opacity: $disabled-opacity;
		}
	}

	#itemSettings {
		.Pane-body {
			padding: 7px;
			.ImageControl:not(:last-child) label {
				margin-bottom: 7px;
			}
		}
	}

}