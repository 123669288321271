@import '../import';

.JsonViewer {
	font-family: monospace;
	font-size: 14px;
	color: #999;
	margin-left: 1.5rem;

	ul {
		list-style-type: none;
		margin: 0 0 0 3px;
		padding-left: 1.5rem;
		border-left: 1px dashed #ccc;
	}

	&-value {
		color: #333;
	}

	&-toggle {
		color: #999;
		background-color: transparent;
		padding: 0;
		margin: 0;
		margin-left: -24px;
		border: none;
		height: 24px;
		width: 21px;
		vertical-align: middle;
		outline: none !important;
		&:hover, &:focus {
			color: $primary-colour;
		}
	}

	&-ellipsis {
		margin: 0 0.2rem;
		display: none;
		.collapsed > & {
			display: inline;
		}
	}
}