@import '../_import';

.FlyoutPage > .container-fluid {
	background-color: $main-background-colour;
	min-height: 100vh;
	margin-left: 200px;
	padding: 0;
	width: auto;
	transition: $transition-slow;
}

.Flyout {
	overflow: hidden;
	width: 200px;
	height: 100%;
	position: fixed;
	background: #404040 url(/assets/img/flyout.png);
	transition: $transition-slow;

	.logo {
		display: block;
		width: 100%;
		height: 52px;
		background-color: $primary-colour;
		// background-image: url(/assets/img/logo-full.svg);
		// background-size: 170px;
		// background-repeat: no-repeat;
		// background-position: 12px center;
		svg {
			width: 170px;
			height: 52px;
			margin-left: 12px;
			fill: #FFF;
		}
	}
}

.collapsed {
	> .Flyout {
	width: 64px;
	}
	> .container-fluid {
		margin-left: 64px;
	}
}

.fullscreen {
	> .Flyout {
		width: 0;
	}
	> .container-fluid {
		margin-left: 0;
	}
}

.flyout-control {
	background-color: #333;
	text-align: right;

	button {
		padding: 0;
		color: #BBB;
		background-color: transparent;

		&:hover,
		&:focus {
			color: #FFF;
		}

		.material-icons {
			height: 38px;
			width: 38px;
			font-size: 38px;
			margin: 7px 13px;
			transition: $transition;
		}
	}
}

/* @media (min-width: 1200px) {
	.Flyout {
		width: 200px;
	}
	.hasFlyout > .container-fluid {
		margin-left: 200px;
	}
} */