@import '../_import';

.UploaderPanel {
	position: relative;
	display: table;
	border: 2px dashed #CCC;
	border-radius: $pane-border-radius;
	width: 100%;
	text-align: center;
	vertical-align: middle;
	background-color: #F5F5F5;
	background-position: center center;
	background-repeat: no-repeat;
	transition: $transition;
	padding: 15px;

	.material-icons {
		@include material-icon(48px);
		margin: 10px auto;
		color: #CCC;
	}
	button {
		text-transform: uppercase;
		background-color: #E6E6E6;
		color: $text-colour;
		border: 1px solid #999;
		opacity: 0.7;
		&:hover,
		&:focus {
			color: $text-colour;
			opacity: 1;
		}
	}

	input[type="file"] {
		display: none;
	}

	&_dragover {
		background-color: #FFF;
		border-color: $primary-colour;
		@include focus-shadow;
	}
}