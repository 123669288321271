@import '../import';

.TimePicker {

	> .btn {
		// we don't want to see this button but it needs to be in the dom for the dropdown to be positioned properly
		padding: 0 !important;
		height: 33px;
		margin-top: -33px;
		visibility: hidden;
	}

	.dropdown-menu {
		padding: 0;
	}

	&-header {
		border-bottom: 1px solid #CCC;
		border-radius: $control-border-radius $control-border-radius 0 0;

		> .btn-icon {
			width: 2rem;
			height: 2rem;
		}
	}

	&-footer {
		border-top: 1px solid #CCC;
		border-radius: 0 0 $control-border-radius $control-border-radius;

		> .btn-icon {
			width: 2rem;
			height: 2rem;
		}
	}

	&-body {
		columns: 3;
		column-gap: 0;
		padding: 0.5rem;
	}

	&-time {
		outline: 0;
		background-color: transparent;
		width: 100%;
		text-align: center;
		font-size: 13px;
		padding: 0.4rem;
		border-radius: $control-border-radius;
		border: 1px solid transparent;
		cursor: pointer;
		transition: $transition;
		&:hover, &:focus {
			outline: 0;
			color: $primary-colour;
			border-color: $border-colour;
		}
	}
}