@import 'import';
/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the IbanElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=iban#elements_create-options-classes
*/

#stripeDialog {

	.Modal-body {
		padding-bottom: 15px;
	}

	.Modal-footer {
		padding: 20px 0 0;
	}

	.StripeElement {
		padding: 0.475rem 0.75rem 0.275rem;

		&--focus {
			border-color: $primary-colour;
			@include focus-shadow;
		}
	
		&--invalid {
			border-color: $primary-colour;
		}
	}

	.mandate-acceptance {
		padding: .5rem;
		margin-top: 1rem;
		border: 1px solid $border-colour;
		border-radius: $control-border-radius;
		background-color: $pane-background-colour;
	}
}