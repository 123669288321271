@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

$primary-colour: #E74C34;
$secondary-colour: #B2B2B2;
$text-colour: #555;
$border-colour: #CCC;
$placeholder-colour: #AAA;
$main-background-colour: #EEE;
$control-colour: #666;
$light-border-colour: #DDD;
$stripe-colour: #F8F8F8;
$pane-border-radius: 6px;
$control-border-radius: 4px;
$divider-colour: #E8E8E8;
$toolbar-colour: #F0F0F0;
$pane-background-colour: #F6F6F6;
$sprite-size: 80;
$disabled-opacity: 0.65;
$error-colour: $primary-colour;
$warning-colour: #F2A037;
$info-colour: #0F0;
$comments-colour: #FEF4A0;
$ipad-pane-height: 676px;

$active-colour: darken(desaturate($primary-colour, 30%), 40%);

$transition-fast: 0.1s ease-in-out;
$transition: 0.2s ease-in-out;
$transition-slow: 0.5s ease-in-out;

@mixin focus-shadow {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba($primary-colour, 0.6) !important;
}

@mixin pane-shadow {
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

@mixin notification {
	text-align: center;
	color: #FFF;
	background-color: lighten($primary-colour, 5%);
	border-radius: $control-border-radius;
	padding: 10px;
}

@mixin __row {	// keeping for now in case there is an issue with the new version
	position: relative;
	display: flex;
	white-space: pre-wrap;	// avoids collapsing whitespace in Typeahead - see https://stackoverflow.com/questions/39325039 (if this is a problem, move it to Typeahead.scss)
	color: inherit;
	padding: 10px 16px 10px 12px;
	min-height: 39px;
	margin-left: 4px;
	cursor: pointer;
	&:hover,
	&:focus,
	&.active {
		color: $primary-colour;
		text-decoration: none;
		outline: 0;
		background-color: #FFF;
		border-left: 4px solid $primary-colour;
		margin-left: 0;
	}
}

@mixin row {
	position: relative;
	display: flex;
	white-space: pre-wrap;	// avoids collapsing whitespace in Typeahead - see https://stackoverflow.com/questions/39325039 (if this is a problem, move it to Typeahead.scss)
	color: inherit;
	padding: 10px 16px 10px 12px;
	min-height: 39px;
	background-color: #FFF;
	border-left: 4px solid #FFF;
	cursor: pointer;
	&:not([disabled]) {
		&:hover,
		&:focus,
		&.active {
			color: $primary-colour;
			text-decoration: none;
			outline: 0;
			border-left: 4px solid $primary-colour;
		}
	}
	&[disabled] {
		cursor: default;
		opacity: .5;
	}
}

@mixin form-item {
	display: block;
	width: 100%;
	border: 1px solid $border-colour;
	border-radius: $control-border-radius;
}

@mixin material-icon($font-size) {
	display: inline-block;
	overflow: hidden;
	height: $font-size;
	width: $font-size;
	font-size: $font-size;
}

@mixin item-button {
	color: #CFCFCF;
	border: 2px solid #CFCFCF;
	border-radius: 4px;
	background-color: #FFF;
	padding: 1px;
	line-height: 10px;
	transition: $transition;
	& > .material-icons {
		@include material-icon(20px);
	}
	&:hover,
	&:focus {
		color: $primary-colour;
		border-color: $primary-colour;
		outline: 0;
	}
	&[disabled] {
		color: #CFCFCF;
		border-color: #CFCFCF;
		opacity: .7;
		cursor: default;
	}
}

@mixin material-icon-style {
	position: absolute;
	pointer-events: none;
	overflow: hidden;
	font-family: 'Material Icons';
	font-size: 24px;
	font-weight: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
}

@mixin venue-header-control {
	// border-left: 1px solid $light-border-colour;
	> button {
		border: none;
		border-radius: 0;
		> .material-icons {
			font-size: 30px;
			width: 30px;
			top: 10px;
			right: 10px;
			color: inherit;
			opacity: 0.5;
		}
	}
}